import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// Import necessary Bulma CSS Framework components.
import "./Footer.library.scss"

// Import component specific styles.
import styles from "./Footer.module.scss"

export const FooterLinks = props => (
  <li className={styles.footerLink}>
    <Link to={props.linkTo} aria-label={'Navigate to the ' + props.text + ' page'}>{props.text}</Link>
  </li>
);

FooterLinks.propTypes = {
  linkTo: PropTypes.string,
  text: PropTypes.string,
}

FooterLinks.defaultProps = {
  linkTo: ``,
  text: ``,
}

export default FooterLinks
