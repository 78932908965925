import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// Import necessary Bulma CSS Framework components.
import "./Header.library.scss"

// Import component specific styles.
import styles from "./Header.module.scss"

export const JumpLinks = props => {
  const [isVisible, setIsVisible] = useState(false)
  const outerElement = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = event => {
    if (!outerElement.current.contains(event.target)) {
      setIsVisible(false)
    }
  };

  return (
    <div className={styles.jumpLinks} ref={outerElement}>
      <div className={styles.dropdown}>
        <div className={styles.dropdownTrigger}>
          <button
            className={isVisible ? styles.jumpLinksButton + " " + styles.active : styles.jumpLinksButton}
            aria-haspopup="true"
            aria-controls={props.id}
            aria-label="View dropdown options"
            onClick={() =>
              isVisible
                ? setIsVisible(false)
                : setIsVisible(true)
            }
          >
            <span className={styles.jumpLinksLabel}>Make Selsey your</span>
            <span className={styles.jumpLinksSpan}>destination</span>
          </button>
        </div>
        <div
          id={props.id}
          className={
            isVisible
              ? styles.dropdownMenu +
                " dropdown-menu " +
                styles.dropdownIsActive
              : styles.dropdownMenu + " dropdown-menu"
          }
          role="menu"
        >
          <ul className={styles.dropdownContent}>
            {props.menu.map(menuItems => {
              return (
                <li key={menuItems.label}>
                  <Link
                    to={"/" + menuItems.destination.slug}
                    className={styles.dropdownItem}
                    aria-label={'Navigate to the ' + menuItems.label + ' page'}
                    onClick={() => setIsVisible(false)}
                  >
                    {menuItems.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

JumpLinks.propTypes = {
  menu: PropTypes.array,
}

JumpLinks.defaultProps = {
  menu: [],
}
