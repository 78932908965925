import React from "react"
import PropTypes from "prop-types"
import GridContainer from "../layout/GridContainer/GridContainer"
import { FaInstagram, FaYoutube } from "react-icons/fa"
import Facebook from "../../images/facebook-logo.svg"
import Twitter from "../../images/twitter-logo.png"

// Import necessary Bulma CSS Framework components.
import "./Footer.library.scss"

// Import component specific styles.
import styles from "./Footer.module.scss"
import FooterLinks from "./FooterLinks"

const FacebookLogo = {
  src: Facebook,
  alt: "Facebook Logo",
}

const TwitterLogo = {
  src: Twitter,
  alt: "Twitter Logo",
}

export const Footer = () => (
  <div className={styles.footerWrapper}>
    <div className={styles.footerBar}></div>
    <footer>
      <GridContainer>
        <div className={styles.footerLinks + " columns"}>
          <ul className={styles.footerLinksList + " column is-6"}>
            <FooterLinks linkTo="/contact" text="Contact" />
            <FooterLinks linkTo="/grateful-thanks" text="Grateful Thanks" />
            <FooterLinks linkTo="/faq" text="Frequently Asked Questions" />
            <FooterLinks
              linkTo="/privacy-policy"
              text="Terms &amp; Conditions"
            />
          </ul>
          <div className={styles.socialMediaWrapper + " column is-6"}>
            <a
              className={styles.firstSocialMedia}
              href="https://www.facebook.com/destinationselsey"
              aria-label="Go to Destination Selseys Facebook page"
              tabIndex="-1"
              target="__blank"
              rel="noreferrer"
            >
              <button className={styles.iconButtonWrapper}>
                <img src={FacebookLogo.src} alt={FacebookLogo.alt} />
              </button>
            </a>
            <a
              className={styles.secondSocialMedia}
              href="https://www.instagram.com/destinationselsey"
              aria-label="Go to Destination Selseys Instagram page"
              tabIndex="-1"
              target="__blank"
              rel="noreferrer"
            >
              <button className={styles.iconButtonWrapper}>
                <FaInstagram />
              </button>
            </a>
            <a
              className={styles.thirdSocialMedia}
              href="https://twitter.com/VisitSelsey"
              aria-label="Go to Destination Selseys Twitter page"
              tabIndex="-1"
              target="__blank"
              rel="noreferrer"
            >
              <button className={styles.iconButtonWrapper}>
                <img src={TwitterLogo.src} alt={TwitterLogo.alt} />
              </button>
            </a>
            <a
              className={styles.fourthSocialMedia}
              href="https://www.youtube.com/channel/UCraGE5Uhyqm4nAzMovfGJNA"
              aria-label="Go to Destination Selseys YouTube page"
              tabIndex="-1"
              target="__blank"
              rel="noreferrer"
            >
              <button className={styles.iconButtonWrapper}>
                <FaYoutube />
              </button>
            </a>
          </div>
        </div>
      </GridContainer>
    </footer>
  </div>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
