import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { MdClose } from "react-icons/md"
import { FiChevronDown } from "react-icons/fi"

// Import component specific styles.
import styles from "../Header/Header.module.scss"

const NavMenu = props => {
  let menuTopLinks = [
    {
      id: "explore",
      label: "Explore",
    },
    {
      id: "whatsOn",
      label: "What's On",
    },
    {
      id: "adventure",
      label: "Adventure",
    },
    {
      id: "wildlifeWilderness",
      label: "Wildlife",
    },
    {
      id: "historyHeritage",
      label: "Heritage",
    },
    {
      id: "eatDrink",
      label: "Eat & Drink",
    },
    {
      id: "localBusiness",
      label: "Local Business",
    },
    {
      id: "accommodation",
      label: "Accommodation",
    },
  ]

  const [activeSubMenu, setActiveSubMenu] = useState(0)
  const [height, setHeight] = useState(0)
  const outerElement = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = event => {
    if (!outerElement.current.contains(event.target)) {
      setActiveSubMenu(0)
    }
  };

  let menuSelector = ""

  if (props.isMobile) {
    menuSelector =
      "." + styles.headerMobileLinks + " ." + styles.navbarDropdownIsOpen
  } else {
    menuSelector =
      "." + styles.headerLinksWrapper + " ." + styles.navbarDropdownIsOpen
  }

  useEffect(() => {
    if (document.querySelector(menuSelector)) {
      const navPane = document.querySelector(menuSelector)
      const navHeight = parseInt(navPane.getBoundingClientRect().height)
      setHeight(navHeight + "px")
    } else {
      setHeight(0)
    }
  }, [activeSubMenu, menuSelector])

  return (
    <ul className={styles.navbarStart} ref={outerElement}>
      {menuTopLinks.map((menuTopLink, index) => {
        return (
          <React.Fragment key={index}>
            {props.menu[menuTopLink.id].length === 1 && (
              <li key={menuTopLink.label + index} className={styles.navbarItem}>
                <Link to={"/" + props.menu[menuTopLink.id][0].destination.slug} aria-label={'Navigate to ' + menuTopLink.label}>
                  {" "}
                  {menuTopLink.label}{" "}
                </Link>
              </li>
            )}

            {props.menu[menuTopLink.id].length > 1 && (
              <li
                key={menuTopLink.label + index}
                className={
                  activeSubMenu === index + 1
                    ? styles.navbarItemHasDropdown +
                      " " +
                      styles.navbarItemHasDropdownActive
                    : styles.navbarItemHasDropdown
                }
              >
                <button
                  onClick={() =>
                    activeSubMenu === index + 1
                      ? setActiveSubMenu(0)
                      : setActiveSubMenu(index + 1)
                  }
                  className={styles.navbarDropdownLabel}
                  aria-label="View menu options"
                >
                  {menuTopLink.label}
                  <span
                    className={
                      activeSubMenu === index + 1
                        ? styles.indicatorActive
                        : styles.indicator
                    }
                  >
                    <FiChevronDown />
                  </span>
                </button>
                <div
                  className={styles.navbarDropdownWrapper}
                  style={
                    activeSubMenu === index + 1
                      ? { height: height }
                      : { height: "0px" }
                  }
                >
                  <div
                    className={
                      activeSubMenu === index + 1
                        ? styles.navbarDropdownIsOpen
                        : styles.navbarDropdownIsClosed
                    }
                  >
                    {chunkArrayInGroups(props.menu[menuTopLink.id], 4).map(
                      (menuColumn, index) => {
                        return (
                          <ul className={styles.menuColumn} key={index}>
                            {menuColumn.map((menuItems, innerIndex) => {
                              return (
                                <li
                                  key={menuItems.destination.title + innerIndex}
                                  className={styles.navbarItem}
                                >
                                  <button className={styles.navbarItemButton} onClick={() => setActiveSubMenu(0)}>
                                    <Link to={"/" + menuItems.destination.slug} aria-label={'Navigate to ' + menuItems.destination.title}>
                                      {menuItems.label ? menuItems.label : menuItems.destination.title}
                                    </Link>
                                  </button>
                                </li>
                              )
                            })}
                          </ul>
                        )
                      }
                    )}
                    <div>
                      <button
                        className={styles.closeModal}
                        onClick={() => setActiveSubMenu(0)}
                        aria-label="Close"
                      >
                        <MdClose />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </React.Fragment>
        )
      })}
    </ul>
  )
}

NavMenu.propTypes = {
  menu: PropTypes.object,
  isMobile: PropTypes.bool,
}

NavMenu.defaultProps = {
  menu: {},
  isMobile: true,
}

// Helper function to split meun items into groups of x.
function chunkArrayInGroups(arr, size) {
  var myArray = []
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size))
  }
  return myArray
}

export default NavMenu
