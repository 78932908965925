/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description, image, twitterCard, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            titleTemplate
            defaultDescription: description
            defaultImage: image
            url
          }
        }
      }
    `
  )

  const stripTrailingSlash = (str) => {
      if(str.substr(-1) === '/') {
          return str.substr(0, str.length - 1);
      }
      return str;
  }

  const metaDescription = description || site.siteMetadata.description
  const metaTwitterCard = twitterCard || `summary`
  const metaImage = image || site.siteMetadata.defaultImage
  const metaUrl = stripTrailingSlash(site.siteMetadata.url) + pathname || site.siteMetadata.url

  const ldSchema = [
    {
      url: metaUrl,
      sameAs: [
        "https://twitter.com/VisitSelsey",
        "https://www.facebook.com/destinationselsey",
        "https://www.instagram.com/destinationselsey",
      ],
      name: title,
      description: metaDescription,
      image: metaImage,
      "@context": "http://schema.org",
      "@type": "WebSite",
    }
  ]

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: metaTwitterCard,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:site`,
          content: "@visitselsey",
        },
      ]}
    >
      <link
        rel="canonical"
        href={metaUrl}
      ></link>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": {
            "@vocab": "http://schema.org/",
          },
          "@graph": [ldSchema],
        })}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  twitterCard: PropTypes.string,
  pathname: PropTypes.string,
}

export default SEO
