import React from "react"
import PropTypes from "prop-types"
import { Logo } from "./Logo"
import { JumpLinks } from "./JumpLinks"
import { HeaderLinks } from "./HeaderLinks"
import logo from "../../images/ds_logo_colour.svg"
import GridContainer from "../layout/GridContainer/GridContainer"
import HeaderMobileLinks from "./HeaderMobileLinks"

// Import necessary Bulma CSS Framework components.
import "./Header.library.scss"

// Import component specific styles.
import styles from "./Header.module.scss"

const image = {
  src: logo,
  alt: "Destination Selsey Logo",
}

const Header = props => (
  <header className="header">
    <GridContainer>
      <div className={styles.header}>
        <div className={styles.headerWrapper}>
          <div className={styles.logoWrapper}>
            <Logo imageSrc={image.src} imageAlt="Destination Selsey" />
          </div>
          <div className={styles.jumpLinksWrapper}>
            <JumpLinks menu={props.subMenu} id="desktop-jumplinks" />
          </div>
          <div className={styles.headerLinksWrapper}>
            <HeaderLinks menu={props.mainMenu} />
          </div>
        </div>
      </div>
    </GridContainer>
    <div className={styles.jumpLinksWrapperMobile + ' mobile-jumplinks'}>
      <JumpLinks menu={props.subMenu} id="mobile-jumplinks"/>
    </div>
    <HeaderMobileLinks menu={props.mainMenu} />
  </header>
)

Header.propTypes = {
  mainMenu: PropTypes.object,
  subMenu: PropTypes.array,
}

Header.defaultProps = {
  mainMenu: {},
  subMenu: [],
}

export default Header
