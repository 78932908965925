import React from "react"
import PropTypes from "prop-types"
import { slide as Menu } from "react-burger-menu"
import NavMenu from "../menus/nav-menu"

// Import necessary Bulma CSS Framework components.
import "./Header.library.scss"

// Import component specific styles.
import styles from "./Header.module.scss"
import "./HeaderMobileLinks.scss"

export const HeaderMobileLinks = props => {
  return (
    <div className={styles.headerMobileLinks}>
      <Menu noOverlay>
        <NavMenu menu={props.menu} isMobile={true} />
      </Menu>
    </div>
  )
}

HeaderMobileLinks.propTypes = {
  menu: PropTypes.object,
  isMobile: PropTypes.bool,
}

HeaderMobileLinks.defaultProps = {
  menu: {},
  isMobile: true,
}

export default HeaderMobileLinks
