import PropTypes from "prop-types"
import React from "react"

import "./GridContainer.library.scss"

const GridContainer = ({ children }) => (
  <div className="container">{children}</div>
)

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GridContainer
