import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"

import "./Layout.scss"
import SEO from "../../seo"
import CookieConsent from "react-cookie-consent"
import { Helmet } from "react-helmet"

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {

      datoCmsMenu {
        ...NavMenu
        ...JumpLinksMenu
      }
    }
  `)
  return (
    <>
      <Helmet>
        <body className="no-outline" />
      </Helmet>
      <SEO
        title={props.metaTitle || props.title}
        description={props.metaDescription || null}
        image={props.metaImage || null}
        twitterCard={props.twitterCard || null}
        pathname={props.pathname || "/"}
      />

      <div className="skipLinkWrapper">
        <a href="#main" aria-label="Skip to main content" className="skipLink">
            Skip to main content
        </a>
      </div>
      <CookieConsent
        className="cookiePolicy"
        location="bottom"
        buttonText="Accept all cookies"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
          This site uses cookies to enhance the user experience, read our <Link to="/privacy-policy">cookie policy</Link> for more info.
      </CookieConsent>
      { !props.removeNav && (
        <Header
          mainMenu={data.datoCmsMenu}
          subMenu={data.datoCmsMenu.makeSelseyYour}
        />)}
        <main className="main" id="main">{props.children}</main>
        { !props.removeNav && (<Footer />)}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  removeNav: PropTypes.bool,
}

export default Layout
