import React from "react"
import PropTypes from "prop-types"
import NavMenu from "../menus/nav-menu"

// Import necessary Bulma CSS Framework components.
import "./Header.library.scss"

// Import component specific styles.
import styles from "./Header.module.scss"

export const HeaderLinks = props => {
  return (
    <nav
      className={styles.navbar}
      role="navigation"
      aria-label="main navigation"
    >
      <div id={styles.navbarBasicExample} className={styles.navbarMenu}>
        <NavMenu menu={props.menu} isMobile={false} />
      </div>
    </nav>
  )
}

HeaderLinks.propTypes = {
  menu: PropTypes.object,
  isMobile: PropTypes.bool,
}

HeaderLinks.defaultProps = {
  menu: {},
  isMobile: false,
}

export default HeaderLinks
