import React from 'react';
import { Link } from "gatsby"

// Import component specific styles.
import styles from "./Header.module.scss"

export default {
  title: 'Header',
};

export const Logo = props => (
  <Link to="/" aria-label="Home page" tabIndex="-1">
    <button className={styles.logoButtonWrapper}>
      <img src={props.imageSrc} alt={props.imageAlt} />
    </button>
  </Link>
);
